<template lang="pug">
  .section
    SectionTitle(
      :required="required"
      :title="title"
      :tooltip="tooltip"
    )
    SectionContent(:contains-dropdown="containsDropdown")
      slot
</template>

<script>
  export default {
    props: {
      tooltip: String,
      title: String,
      containsDropdown: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      }
    },

    components: {
      SectionTitle: () => import("./SectionTitle"),
      SectionContent: () => import("./SectionContent"),
      AppDropdown: () => import("@/components/elements/AppDropdown")
    }
  }
</script>

<style lang="sass" scoped>
  .section
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%
</style>
